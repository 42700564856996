
import {computed, defineComponent, reactive} from 'vue';
import {translateMixin} from "@/i18n";
import authStore from "@/modules/auth/store/authStore";
import appStore from "@/modules/app/store/appStore";
import beastsStore from "@/modules/bestiary/store/beastsStore";
import demoStore from "@/modules/demo/store/demoStore";

export default defineComponent({
  name: 'Home',
  mixins: [
    translateMixin,
  ],
  setup() {
    const bestiarySummary = reactive({
      beastCount: computed(() => beastsStore.state.beastList.length),
      stationCount: computed(() => beastsStore.state.breedingStations.length)
    })
    const userAuthenticated = computed(() => authStore.getters.isLoggedIn())

    return {
      appName: appStore.state.appName,
      userAuthenticated,
      beginDemo: () => {
        demoStore.actions.initDemoData()
      },
      bestiarySummary,
    }
  },
});
